import "./styles.css";

export const NavBar = () => {


  const isAuthenticated =
    sessionStorage.getItem("profile") === null ? false : true;


  const toggleMenu = (e) => {
    const menu = document.getElementById("sub-menu")
    menu.className==="sub=menu"?
    menu.className += "  hidden" :
    menu.className="sub=menu"
  };

  // const userName = isAuthenticated
  //   ? JSON.parse(sessionStorage.getItem("profile")).name.replace(
  //       /"([^"]+)":/g,
  //       "$1:"
  //     )
  //   : "Guest";

  // const greeting = "Welcome, " + userName;

  // return (
  //   <div className="nav-grid">
  //     <a className="nav-item nav-icon" href="/">
  //       <i className="fa-solid fa-house"></i>
  //     </a>
  //     <a className="nav-item" href="/gigs">
  //       {"Come and see us"}
  //     </a>
  //     <a className="nav-item" href="/band">
  //       {"Meet the gang"}
  //     </a>
  //     <a className="nav-item" href="/tunes" hidden={!isAuthenticated}>
  //       {"Tunes"}
  //     </a>
  //     <a className="nav-item" href="/login">
  //       {isAuthenticated ? "Log Out" : "Login/Register"}
  //     </a>
  //   </div>
  // );
  return (
    <div className="topnav" id="myTopnav">
       <div className="burger fa-solid fa-bars" onClick={toggleMenu}></div>
        <div id="sub-menu" className="sub-menu hidden">
          <a href="/">{"Home"}</a>
          <a href="/gigs">{"Gigs"}</a>
          <a href="/band">{"Meet the gang"}</a>
          <span hidden={!isAuthenticated}>
            <a href="/tunes">{"Tunes"}</a>
          </span>
          <a href="/login">{isAuthenticated ? "Log Out" : "Login/Register"}</a>
         </div>

      
          </div>

  );
};

export default NavBar;
