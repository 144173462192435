import React from 'react'

const GigWidget = (gig) => {

    const d = new Date(gig.When)
    const gigDate = d.toDateString()
    const gigTime = (d.toLocaleTimeString("en-us",{hour12:true,hour:'numeric',minute:'numeric'} ))

 

return <li className='gig-list-item' key={gig._id}>
{gigDate} {gigTime}: {gig.What} <br/> {gig.Where} <a href={gig.Link}>{gig.LinkText}</a>
</li>
}
export default GigWidget