import {useState, React} from 'react'
import {getGigs} from  "../../api"
import GigWidget from './Components/GigWidget'
import "./styles.css"
const Gigs = () => {

    const [gigList, setGigList] = useState(null)



    const getGigList = async() => {
    try{const response = await getGigs();
        const gigs = await response.data.result;
        setGigList(gigs)}
    catch (err) {
        console.log(err);
      }
    }
    
    const gigTitles =[]
    
    if (!gigList) {
        getGigList()
    } else {
        gigList.forEach(gig => {gigTitles.push(GigWidget(gig))    
        });
    }
    
return <div className='gigs'>
    <div className='gigs-head'>
    <h1>
        Upcoming gigs
    </h1>
    <div hidden={gigList}>
    <i class="fa-solid fa-arrows-spin loader gigs-head" ></i>
    <p className='loader-text'>Please be patient - this can take a while</p>
    </div></div>
<div className='gigs-list'>
    <ul className='gig-items'>
        {gigTitles}
    </ul>
    </div>
</div>
}
export default Gigs

// <li>22nd June, 2pm: <a className='link' href="https://www.chiselboroughvillage.co.uk/fairflowerfestival">Chiselborough Fair and Flower Festival</a></li>
//         <li>27th June, 7.30pm "Jazz at the Cat": Cat Head Inn, Chiselborough </li>
//         <li>27th June, 7.30pm "Jazz at the Cat": Cat Head Inn, Chiselborough </li>
//         <li>27th June, 7.30pm "Jazz at the Cat": Cat Head Inn, Chiselborough </li>
//         <li>6th July, 6.30pm: Babcary Church</li>
//         <li>2nd August <a className='link' href="https://fannyhatstand.com/">Fanny Hatstand Festival (Acoustic Sign-up Stage)</a> </li>