import {useState, React} from 'react'
import TuneWidget from './Components/TuneWidget'
import {getTunes} from "../../api"


import "./styles.css"
const Tunes = () => {
 const [tuneList, setTuneList] = useState(null)
 const [invalid, setInvalid] = useState(null)

 const isAuthenticated =
  sessionStorage.getItem("profile") === null ? false : true;






const getTuneList = async() => {
try{const response = await getTunes();
    const tunes = await response.data.result;
    if (response.data.message === "jwt expired") {
        setInvalid("jwt expired")}
        else{
    setTuneList(tunes)}
}
catch (err) {
    console.log(err);
  }
}

const tuneTitles =[]

if (!tuneList) {
    getTuneList()
} else {
    tuneList.forEach(tune => {tuneTitles.push(TuneWidget(tune))    
    });
}
const WelcomeText = !isAuthenticated?"You need to be logged in to view this page":invalid?"your session has expired. Please log out and log in again":"Tunes"
return <div>
<h1 className='tunes-head'> {WelcomeText} </h1>

<ul className='tunes-list'>
{tuneTitles}
</ul>
</div>
}
export default Tunes