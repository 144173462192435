import TextFieldGroup from "../../Common/TextFieldGroup";
import { Button } from "rsuite";
import { sendPwLink } from "../../../api";
import { useState } from "react";
import ModalHeader from "rsuite/esm/Modal/ModalHeader";
import ModalBody from "rsuite/esm/Modal/ModalBody";
import { useNavigate } from "react-router-dom";

const ForgotPw = () => {
  const navigate = useNavigate();

  const initialState = { email: "" };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const header = () => {
    return "A reset link will be sent to your email:";
  };

  const formContent = () => {
    return (
      <div>
        <TextFieldGroup
          placeholder="email address"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={errors.email}
        />
      </div>
    );
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleLogin = async (e) => {
    e.preventDefault(); // prevent screen reload
    setLoading(true);
    try {
        await sendPwLink(formData)
    } catch (error) {
      console.log(error);
      setErrors(error.response.data);
    }
    finally{
        navigate('/')
        navigate(0)
        setLoading(false)}
  };

  return (
    <div>
      <ModalHeader>{header()}</ModalHeader>
      <ModalBody>{formContent()}</ModalBody>
      <Button onClick={handleLogin}
      loading={loading}>Send link</Button>
    </div>
  );
};
export default ForgotPw
