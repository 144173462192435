import React from "react";

import NavBar from "./components/NavZone/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Auth from "./components/Auth/Auth";
import Gigs from "./components/Gigs/Gigs";
import Band from "./components/Band/Band"
import Tunes from "./components/Tunes/Tunes";
import "./styles/styles.css"
import Footer from "./components/Footer/Footer";
import SideBar from "./components/Sidebar/Sidebar";
import Logo from "./images/logo_transparent.png"
import LeadSheet from "./components/Tunes/LeadSheet";

const App = () => {
  return (
    <div className="site-style site-grid">

        <header>
        <NavBar />
        </header>
        {/* <div id="logo">
        <img className="logo" src= {Logo} alt="logo" />
        </div>
        <aside>
        <SideBar />
        </aside> */}
      <main >
        <Routes>
          <Route path="/pwchange/:token" element={<Auth />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/" element={<Home />} />
          <Route path="/gigs" element={<Gigs />} />
          <Route path="/band" element={<Band />} />
          <Route path="/tunes" element={<Tunes />} />
          <Route path="/leadSheet" element={<LeadSheet />} />

        </Routes>
      </main>
      <footer>
        <Footer />
              </footer>

    </div>
  );

};
export default App;
