import { Button } from "rsuite";
import { useState } from "react";
import ModalHeader from "rsuite/esm/Modal/ModalHeader";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  const initialState = { email: "", password: "" };
  const [loading, setLoading] = useState(false);

  const header = () => {
    return "Are you sure you want to log out?";
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      sessionStorage.clear()
    } catch (error) {
      console.log(error);
    } finally {
      navigate("/");
      navigate(0);
      setLoading(false);
    }
  };

  return (
    <div>
      <ModalHeader>{header()}</ModalHeader>
      <br/>
      <Button onClick={handleLogout} loading={loading}>
        Logout
      </Button>
    </div>
  );
};
export default Logout;
