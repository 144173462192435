import {React, useState} from 'react'






const Leadsheet = (tune) => {
       
        const tuneUrl = "images/Avalon.pdf"
        

return <div>
<a href={tuneUrl}>Leadsheet</a>

</div>
}
export default Leadsheet